import {Trans, withTranslation} from "react-i18next";
import * as Icon from "react-bootstrap-icons";
import {Alert, Container, Dropdown, DropdownButton, Row, Table} from "react-bootstrap";
import {Helmet} from "react-helmet-async";
import React, {useEffect, useState} from "react";

const Home = ({t}) => {

    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState(null);

    const [brands] = useState([
        {"make": "Alfa Romeo"},
        {"make": "Alpina"},
        {"make": "Alpine"},
        {"make": "Aston Martin"},
        {"make": "Audi"},
        {"make": "Bentley"},
        {"make": "BMW"},
        {"make": "Bugatti"},
        {"make": "Citroën"},
        {"make": "Dacia"},
        {"make": "DS"},
        {"make": "Ferrari"},
        {"make": "Fiat"},
        {"make": "Ford"},
        {"make": "Honda"},
        {"make": "Hyundai"},
        {"make": "Infiniti"},
        {"make": "Isuzu"},
        {"make": "Jaguar"},
        {"make": "Jeep"},
        {"make": "Kia"},
        {"make": "Lamborghini"},
        {"make": "Landrover"},
        {"make": "Lexus"},
        {"make": "Lotus"},
        {"make": "MAN"},
        {"make": "Maserati"},
        {"make": "Mazda"},
        {"make": "McLaren"},
        {"make": "Mercedes"},
        {"make": "MG"},
        {"make": "Mini"},
        {"make": "Mitsubishi"},
        {"make": "Nissan"},
        {"make": "Opel"},
        {"make": "Peugeot"},
        {"make": "Porsche"},
        {"make": "Renault"},
        {"make": "Saab"},
        {"make": "Seat"},
        {"make": "Skoda"},
        {"make": "Smart"},
        {"make": "SsangYong"},
        {"make": "Subaru"},
        {"make": "Suzuki"},
        {"make": "Toyota"},
        {"make": "Volkswagen"},
        {"make": "Volvo"}
    ]);

    const [models, setModels] = useState(null);
    const [years, setYears] = useState(null);
    const [engines, setEngines] = useState(null);

    const [make, setMake] = useState(null);
    const [model, setModel] = useState(null);
    const [generation, setGeneration] = useState(null);
    const [engine, setEngine] = useState(null);

    useEffect(() => {
        if (!make) {
            return;
        }
        console.log('getModel for make', make)

        const url = 'https://tuning-server-portal.com/api/getModel';

        const headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded',
            // 'Access-Control-Allow-Origin': 'https://tuning-server-portal.com',
            // 'Access-Control-Allow-Methods': 'POST,GET,PUT,DELETE,OPTIONS',
            // 'Access-Control-Request-Method': 'POST',
            // 'Access-Control-Request-Headers': 'Content-Type',
            // 'Sec-Fetch-Dest': 'empty',
            // 'Sec-Fetch-Mode': 'cors',
            // 'Sec-Fetch-Site': 'same-origin',
            // 'X-Requested-With': 'XMLHttpRequest'
        };

        const data = {
            'make': make
        };

        const request = new Request(url, {
            method: 'POST',
            mode: 'cors',
            redirect: 'follow',
            headers: new Headers(headers),
            body: new URLSearchParams(data)
        });

        // 'http://localhost:8000/brands'
        fetch(request)
            .then(res => {
                console.log('res', res)
                return res.json();
            })
            .then(data => setModels(data))
            .catch((e) => handleError(e));

        // axios
        //     .post(url, data, {
        //         headers: headers
        //     })
        //     .then(({res}) => {
        //         console.log('axios.res', res)
        //     })

    }, [make]);

    useEffect(() => {
        if (!make || !model) {
            return;
        }
        console.log('getGeneration for model', model)

        const url = 'https://tuning-server-portal.com/api/getGeneration';

        const headers = {
            'Content-Type': 'application/json'
        };

        const body = {
            'make': make,
            'model': model
        };

        const request = new Request(url, {
            method: 'POST',
            mode: 'cors',
            redirect: 'follow',
            headers: new Headers(headers),
            body: JSON.stringify(body)
        });

        // 'http://localhost:8000/models'
        fetch(request)
            .then(res => res.json())
            .then(data => setYears(data))
            .catch(e => handleError(e));

    }, [make, model]);

    useEffect(() => {
        if (!make || !model || !generation) {
            return;
        }
        console.log('getEngine for generation', generation)

        const url = 'https://tuning-server-portal.com/api/getEngine';

        const headers = {
            'Content-Type': 'application/json'
        };

        const body = {
            'make': make,
            'model': model,
            'generation': generation
        };

        const request = new Request(url, {
            method: 'POST',
            mode: 'cors',
            redirect: 'follow',
            headers: new Headers(headers),
            body: JSON.stringify(body)
        });

        // 'http://localhost:8000/engines'
        fetch(request)
            .then(res => res.json())
            .then(data => setEngines(data))
            .catch(e => handleError(e));

    }, [make, model, generation]);

    const handleError = (e) => {
        setMake(null);
        setModel(null);
        setGeneration(null);
        setEngine(null);
        setAlertMessage(e.message);
        setShowAlert(true);
    };

    const handleBrandClick = (brand) => {
        setEngine(null);
        setGeneration(null);
        setModel(null);
        setMake(brand);
    };

    const handleModelClick = (model) => {
        setEngine(null);
        setGeneration(null);
        setModel(model);
    };

    const handleYearClick = (year) => {
        setEngine(null);
        setGeneration(year);
    };

    const handleEngineClick = (engine) => {
        setEngine(engine);
    };

    return (
        <>
            <Helmet prioritizeSeoTags>
                <title>{t('messages.head.title')} | {t('messages.navigation.home')}</title>
            </Helmet>
            {showAlert &&
                <Alert variant="danger" onClose={() => setShowAlert(false)} dismissible>
                    <Alert.Heading>
                        {t('messages.alert.something.wrong')}
                    </Alert.Heading>
                    <p>
                        {alertMessage}
                    </p>
                </Alert>
            }
            <Container className="content-text text-center mt-3">
                <h1 className="mt-3">
                    <Trans i18nKey="messages.page.home.h1"/>
                </h1>
                <div className="headline-container">
                    <div className="headline-div">
                        <span aria-hidden="true">
                            <Icon.CarFront className={"headline-icon"} size={"24"}/>
                        </span>
                    </div>
                </div>
                <div className={"mt-3 mb-5"}>
                    <h2 className="mt-3 text-uppercase">
                        <Trans i18nKey="messages.component.tuning.header"/>
                    </h2>
                    <div className="mt-3">
                        <Trans i18nKey="messages.component.tuning.description"/>
                    </div>
                </div>
                <div className={"mt-3 mb-5"}>
                    <h2 className="mt-3 text-uppercase">
                        <Trans i18nKey="messages.component.tuning.calculate"/>
                    </h2>
                </div>
                <Row className="mt-3">
                    <DropdownButton
                        id="brand-dropdown-item-button"
                        className={"engine-select-button"}
                        variant="secondary"
                        title={make ? make : t('messages.component.tuning.brand.placeholder')}
                    >
                        <Dropdown.ItemText>
                            <Trans i18nKey="messages.component.tuning.brand.choose"/>
                        </Dropdown.ItemText>
                        {brands && brands.map((brand) => (
                            <Dropdown.Item
                                as="button"
                                key={brand.make}
                                onClick={() => handleBrandClick(brand.make)}
                            >
                                {brand.make}
                            </Dropdown.Item>
                        ))}
                    </DropdownButton>
                </Row>
                <Row className="mt-3">
                    <DropdownButton
                        id="model-dropdown-item-button"
                        className={"engine-select-button"}
                        variant="secondary"
                        title={model ? model : t('messages.component.tuning.model.placeholder')}
                    >
                        <Dropdown.ItemText>
                            <Trans i18nKey="messages.component.tuning.model.choose"/>
                        </Dropdown.ItemText>
                        {models && models.map((model) => (
                            <Dropdown.Item
                                as="button"
                                key={model}
                                onClick={() => handleModelClick(model)}
                            >
                                {model}
                            </Dropdown.Item>
                        ))}
                    </DropdownButton>
                </Row>
                <Row className="mt-3">
                    <DropdownButton
                        id="year-dropdown-item-button"
                        className={"engine-select-button"}
                        variant="secondary"
                        title={generation ? generation : t('messages.component.tuning.generation.placeholder')}
                    >
                        <Dropdown.ItemText min-width={200}>
                            <Trans i18nKey="messages.component.tuning.generation.choose"/>
                        </Dropdown.ItemText>
                        {years && years.map((year) => (
                            <Dropdown.Item
                                as="button"
                                key={year}
                                onClick={() => handleYearClick(year)}
                            >
                                {year}
                            </Dropdown.Item>
                        ))}
                    </DropdownButton>
                </Row>
                <Row className="mt-3">
                    <DropdownButton
                        id="engine-dropdown-item-button"
                        className={"engine-select-button"}
                        variant="secondary"
                        title={engine ?
                            engine.engine_type +
                            " (" +
                            engine.std_bhp.replace('hp', t('messages.component.tuning.result.unit.hp')) +
                            ")" :
                            t('messages.component.tuning.engine.placeholder')}
                    >
                        <Dropdown.ItemText>
                            <Trans i18nKey="messages.component.tuning.engine.choose"/>
                        </Dropdown.ItemText>
                        {engines && engines.map((engine) => (
                            <Dropdown.Item
                                as="button"
                                key={engine.id}
                                onClick={() => handleEngineClick(engine)}
                            >
                                {engine.engine_type}
                                &nbsp;(
                                {engine.std_bhp.replace('hp', t('messages.component.tuning.result.unit.hp'))}
                                &nbsp;/&nbsp;
                                {Math.round(engine.std_bhp.split(' ')[0] / 1.35962)}
                                &nbsp;{t('messages.component.tuning.result.unit.kw')}
                                )
                            </Dropdown.Item>
                        ))}
                    </DropdownButton>
                </Row>
                <Row className="mt-3">
                    {engine && (
                        <Table responsive striped bordered>
                            <thead>
                            <tr>
                                <th colSpan={4}>{engine.title}</th>
                            </tr>
                            <tr>
                                <th>
                                    <Trans i18nKey="messages.component.tuning.result.description.head"/>
                                </th>
                                <th>
                                    <Trans i18nKey="messages.component.tuning.result.standard.head"/>
                                </th>
                                <th>
                                    <Trans i18nKey="messages.component.tuning.result.optimized.head"/>
                                </th>
                                <th>
                                    <Trans i18nKey="messages.component.tuning.result.difference.head"/>
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>
                                    <Trans i18nKey="messages.component.tuning.result.description.performance"/>
                                </td>
                                <td>
                                    {engine.std_bhp.split(' ')[0]}
                                    &nbsp;{t('messages.component.tuning.result.unit.hp')}
                                </td>
                                <td>
                                    {engine.tuned_bhp.split(' ')[0]}
                                    &nbsp;{t('messages.component.tuning.result.unit.hp')}
                                </td>
                                <td>
                                    {engine.tuned_bhp.split(' ')[0] - engine.std_bhp.split(' ')[0]}
                                    &nbsp;{t('messages.component.tuning.result.unit.hp')}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <Trans i18nKey="messages.component.tuning.result.description.torque"/>
                                </td>
                                <td>
                                    {engine.std_torque.split(' ')[0]}
                                    &nbsp;{t('messages.component.tuning.result.unit.nm')}
                                </td>
                                <td>
                                    {engine.tuned_torque.split(' ')[0]}
                                    &nbsp;{t('messages.component.tuning.result.unit.nm')}
                                </td>
                                <td>
                                    {engine.tuned_torque.split(' ')[0] - engine.std_torque.split(' ')[0]}
                                    &nbsp;{t('messages.component.tuning.result.unit.nm')}
                                </td>
                            </tr>
                            </tbody>
                        </Table>
                    )}
                </Row>
                <div className="headline-container">
                    <div className="headline-div">
                        <span aria-hidden="true">
                            <Icon.FuelPump className={"headline-icon"} size={"24"}/>
                        </span>
                    </div>
                </div>
                <div className={"mt-3 mb-5"}>
                    <h2 className="mt-3 text-uppercase">
                        <Trans i18nKey="messages.page.home.tuning.eco.title"/>
                    </h2>
                    <div className="mt-3">
                        <Trans i18nKey="messages.page.home.tuning.eco.description"/>
                    </div>
                </div>
                <div className="headline-container">
                    <div className="headline-div">
                        <span aria-hidden="true">
                            <Icon.RocketTakeoff className={"headline-icon"} size={"24"}/>
                        </span>
                    </div>
                </div>
                <div className={"mt-3 mb-5"}>
                    <h2 className="mt-3 text-uppercase">
                        <Trans i18nKey="messages.page.home.tuning.stage.title"/>
                    </h2>
                    <div className="mt-3">
                        <Trans i18nKey="messages.page.home.tuning.stage.description"/>
                    </div>
                </div>
                <div className="headline-container">
                    <div className="headline-div">
                        <span aria-hidden="true">
                            <Icon.Fire className={"headline-icon"} size={"24"}/>
                        </span>
                    </div>
                </div>
                <div className={"mt-3 mb-5"}>
                    <h2 className="mt-3 text-uppercase">
                        <Trans i18nKey="messages.page.home.tuning.sport.title"/>
                    </h2>
                    <div className="mt-3">
                        <Trans i18nKey="messages.page.home.tuning.sport.description"/>
                    </div>
                </div>
                <div className="headline-container">
                    <div className="headline-div">
                        <span aria-hidden="true">
                            <Icon.ExclamationDiamond className={"headline-icon"} size={"24"}/>
                        </span>
                    </div>
                </div>
                <div className={"mt-3 mb-5"}>
                    <h2 className="mt-3 text-uppercase">
                        <Trans i18nKey="messages.page.home.tuning.issue.title"/>
                    </h2>
                    <div className="mt-3">
                        <Trans i18nKey="messages.page.home.tuning.issue.description"/>
                    </div>
                </div>
                <div className="headline-container">
                    <div className="headline-div">
                        <span aria-hidden="true">
                            <Icon.Cpu className={"headline-icon"} size={"24"}/>
                        </span>
                    </div>
                </div>
                <div className={"mt-3 mb-5"}>
                    <h2 className="mt-3 text-uppercase">
                        <Trans i18nKey="messages.page.home.h2.1"/>
                    </h2>
                    <div className="mt-3">
                        <Trans i18nKey="messages.page.home.text.1"/>
                    </div>
                </div>
                <div className={"mb-5"}>
                    <h2 className="mt-3 text-uppercase">
                        <Trans i18nKey="messages.page.home.h2.2"/>
                    </h2>
                    <div className="mt-3">
                        <Trans i18nKey="messages.page.home.text.2"/>
                    </div>
                </div>
                <div className={"mb-5"}>
                    <h2 className="mt-3 text-uppercase">
                        <Trans i18nKey="messages.page.home.h2.3"/>
                    </h2>
                    <div className="mt-3">
                        <Trans i18nKey="messages.page.home.text.3"/>
                    </div>
                </div>
                <div className={"mb-5"}>
                    <h2 className="mt-3 text-uppercase">
                        <Trans i18nKey="messages.page.home.h2.4"/>
                    </h2>
                    <div className="mt-3">
                        <Trans i18nKey="messages.page.home.text.4"/>
                    </div>
                </div>
                <div className={"mb-5"}>
                    <h2 className="mt-3 text-uppercase">
                        <Trans i18nKey="messages.page.home.h2.5"/>
                    </h2>
                    <div className="mt-3">
                        <Trans i18nKey="messages.page.home.text.5"/>
                    </div>
                </div>
                <div className={"mb-5"}>
                    <h2 className="mt-3 text-uppercase">
                        <Trans i18nKey="messages.page.home.h2.6"/>
                    </h2>
                    <div className="mt-3">
                        <Trans i18nKey="messages.page.home.text.6"/>
                    </div>
                </div>
            </Container>
        </>
    );
};

export default withTranslation()(Home);