import {withTranslation} from 'react-i18next';
import {Outlet} from "react-router-dom";

function Section({t, i18n}) {

    return (
        <section>
            <Outlet/>
        </section>
    );
}

export default withTranslation()(Section);