import Header from './Header';
import Footer from "./Footer";
import Section from "./Section";

const Layout = () => {
    return (
        <>
            <Header/>
            <Section/>
            <Footer/>
            <svg aria-hidden="true" focusable="false" style={{width: 0, height: 0, position: "absolute"}}>
                <linearGradient id="icon-gradient" x1="0" y1="1" x2="1" y2="0">
                    <stop offset="0%" stopColor="var(--color-stop-2)"/>
                    <stop offset="100%" stopColor="var(--color-stop-3)"/>
                </linearGradient>
            </svg>
        </>
    )
};

export default Layout;