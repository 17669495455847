import {withTranslation} from 'react-i18next';
import * as Icon from 'react-bootstrap-icons';
import {FormattedMessage, IntlProvider} from 'react-intl';

const copyrightMessages = {
    copyrightMessage: "© {ts, date, ::yyyy} ",
}

function Footer({t, i18n}) {

    return (
        <footer>
            <div>
                <hr/>
                <div className="text-center">
                    <div className={"m-2"}>
                        <a className="fab-item m-2"
                           title={t('messages.page.contact.email.title')}
                           href={"mailto:" + t('messages.page.contact.email.to') + "?Subject=" + t('messages.page.contact.email.subject')}
                           target="_blank"
                           rel="noopener noreferrer"
                        >
                            <Icon.Envelope className="text-info" size={"24"}/>
                        </a>
                        <a className="fab-item m-2"
                           title={t('messages.page.contact.facebook.title')}
                           href={"https://www.facebook.com/" + t('messages.page.contact.facebook.to')}
                           target="_blank"
                           rel="noopener noreferrer"
                        >
                            <Icon.Facebook className="facebook-icon" size={"24"}/>
                        </a>
                        <a className="fab-item m-2"
                           title={t('messages.page.contact.instagram.title')}
                           href={"https://www.instagram.com/" + t('messages.page.contact.instagram.to')}
                           target="_blank"
                           rel="noopener noreferrer"
                        >
                            <Icon.Instagram className="instagram-icon" size={"24"}/>
                        </a>
                        <a className="fab-item m-2"
                           title={t('messages.page.contact.twitter.title')}
                           href={"https://twitter.com/" + t('messages.page.contact.twitter.to')}
                           target="_blank"
                           rel="noopener noreferrer"
                        >
                            <Icon.Twitter className="twitter-icon" size={"24"}/>
                        </a>
                        <a className="fab-item m-2"
                           title={t('messages.page.contact.whatsapp.title')}
                           href={"https://wa.me/" + t('messages.page.contact.whatsapp.to')}
                           target="_blank"
                           rel="noopener noreferrer"
                        >
                            <Icon.Whatsapp className="whatsapp-icon" size={"24"}/>
                        </a>
                    </div>
                    <div className={"mb-3"}>
                        <IntlProvider messages={copyrightMessages} locale={i18n.language} defaultLocale="de">
                            <FormattedMessage
                                id="copyrightMessage"
                                defaultMessage="© {ts, date, ::yyyy} "
                                values={{ts: Date.now()}}
                            />
                        </IntlProvider>
                        <a className={"a-link"} href={t('messages.page.contact.web.link')}>
                            {t('messages.page.contact.web.text')}
                        </a>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default withTranslation()(Footer);