import './styles/App.scss';
import {Route, Routes} from 'react-router-dom';
import Layout from './components/layout/Layout';
import Home from './components/pages/Home';
import NoPage from './components/pages/NoPage';
import {withTranslation} from "react-i18next";
import {Helmet, HelmetProvider} from "react-helmet-async";
import React from "react";

function App({t, i18n}) {

    return (
        <HelmetProvider>
            <div className="app-container">
                <Helmet prioritizeSeoTags>
                    <title>{t('messages.head.title')}</title>
                    <meta content={t('messages.head.meta.description')} name="description"/>
                    <meta content={t('messages.head.meta.keywords')} name="keywords"/>
                </Helmet>
                <Routes>
                    <Route path="/" element={<Layout/>}>
                        <Route index element={<Home/>}/>
                        <Route path="*" element={<NoPage/>}/>
                    </Route>
                </Routes>
            </div>
        </HelmetProvider>
    );
}

export default withTranslation()(App);