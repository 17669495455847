import {Trans, withTranslation} from "react-i18next";
import {Helmet} from "react-helmet-async";
import React from "react";

const NoPage = ({t}) => {
    return (
        <>
            <Helmet>
                <title>{t('messages.head.title')} | {t('messages.navigation.not.found')}</title>
            </Helmet>
            <div className="container content-text text-center mt-3">
                <h1>
                    <Trans i18nKey="messages.page.not.found.h1"/>
                </h1>
                <div>
                    <p>
                        <Trans i18nKey="messages.page.not.found.text"/>
                    </p>
                </div>
            </div>
        </>
    );
};

export default withTranslation()(NoPage);