import Container from 'react-bootstrap/Container';
import {Col, Nav, Navbar, NavDropdown, Row} from 'react-bootstrap';
import {Trans, withTranslation} from 'react-i18next';
import * as Icon from "react-bootstrap-icons";

function Header({t, i18n}) {

    const navDropdownTitle = (
        <>
            <span className={"flag-icon flag-icon-" + i18n.resolvedLanguage}></span>
            &nbsp;{t('messages.language.' + i18n.resolvedLanguage)}
        </>
    );

    return (
        <header>
            <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark" fixed={"top"}>
                {/*<Container fluid>*/}
                <Container>
                    <Navbar.Brand href={"/"} className={"p-0"}>
                        <Container fluid>
                            <Row>
                                <Col className={"p-0"}>
                                    <img
                                        alt=""
                                        src="/images/logo.svg"
                                        height="36"
                                        className="d-inline-block align-top"
                                    />
                                </Col>
                                <Col className={"p-0"}>
                                    <Container className={"site-name"}>
                                        <span className={"site-brand-text-secondary-color align-middle"}>
                                            {t('messages.navigation.brand.name')}
                                        </span>
                                        <span className={"site-brand-text-primary-color align-middle"}>
                                            {t('messages.navigation.brand.domain')}
                                        </span>
                                    </Container>
                                </Col>
                            </Row>
                        </Container>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav"/>
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="me-auto">
                            <Nav.Link href={"/"}>
                                <Trans i18nKey="messages.navigation.home"/>
                            </Nav.Link>
                        </Nav>
                        {/*<Form className="d-flex">*/}
                        {/*    <Form.Control*/}
                        {/*        type="search"*/}
                        {/*        placeholder="Search"*/}
                        {/*        className="me-2"*/}
                        {/*        aria-label="Search"*/}
                        {/*    />*/}
                        {/*    <Button variant="outline-success">Search</Button>*/}
                        {/*</Form>*/}
                        <Nav>
                            <div className={"m-2"}>
                                <a className="fab-item m-2"
                                   title={t('messages.page.contact.email.title')}
                                   href={"mailto:" + t('messages.page.contact.email.to') + "?Subject=" + t('messages.page.contact.email.subject')}
                                   target="_blank"
                                   rel="noopener noreferrer"
                                >
                                    <Icon.Envelope className="text-info" size={"24"}/>
                                </a>
                                <a className="fab-item m-2"
                                   title={t('messages.page.contact.instagram.title')}
                                   href={"https://www.instagram.com/" + t('messages.page.contact.instagram.to')}
                                   target="_blank"
                                   rel="noopener noreferrer"
                                >
                                    <Icon.Instagram className="instagram-icon" size={"24"}/>
                                </a>
                                <a className="fab-item m-2"
                                   title={t('messages.page.contact.whatsapp.title')}
                                   href={"https://wa.me/" + t('messages.page.contact.whatsapp.to')}
                                   target="_blank"
                                   rel="noopener noreferrer"
                                >
                                    <Icon.Whatsapp className="whatsapp-icon" size={"24"}/>
                                </a>
                            </div>
                        </Nav>
                        <div className="vr d-none d-lg-flex h-200 mx-lg-2 vertical-hr"></div>
                        <Nav>
                            <NavDropdown
                                id="nav-dropdown-dark-example"
                                title={navDropdownTitle}
                                menuVariant="dark"
                            >
                                <NavDropdown.Item onClick={() => i18n.changeLanguage('ru-RU')}>
                                    <span className={"flag-icon flag-icon-ru-RU"}></span>
                                    &nbsp;{t('messages.language.ru-RU')}
                                </NavDropdown.Item>
                                <NavDropdown.Item onClick={() => i18n.changeLanguage('en-EN')}>
                                    <span className={"flag-icon flag-icon-en-EN"}></span>
                                    &nbsp;{t('messages.language.en-EN')}
                                </NavDropdown.Item>
                                <NavDropdown.Item onClick={() => i18n.changeLanguage('de-DE')}>
                                    <span className={"flag-icon flag-icon-de-DE"}></span>
                                    &nbsp;{t('messages.language.de-DE')}
                                </NavDropdown.Item>
                                <NavDropdown.Item onClick={() => i18n.changeLanguage('uk-UA')}>
                                    <span className={"flag-icon flag-icon-uk-UA"}></span>
                                    &nbsp;{t('messages.language.uk-UA')}
                                </NavDropdown.Item>
                            </NavDropdown>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </header>
    );
}

export default withTranslation()(Header);